<template>
  <div
    class="auth-wrapper auth-v2"
    style="background-color: ghostwhite;"
  >
    <b-row class="auth-inner m-0">

      <!-- /Brand logo-->
      
      <!-- Left Text-->

      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        sm="6"
        md="5"
        lg="4"
        class="d-flex m-auto mt-0 align-items-center auth-bg px-2 p-lg-5"
        style="background-color: white; border-radius: 10px; box-shadow: 1px 1px 10px rgb(0 0 0 / 70%)"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="img-wrapper d-flex m-auto flex-column align-content-center align-items-center">
            <img
              src="../assets/images/logo/Advantage_Icon_GoldFoil.png"
              alt="logo image"
              class="w-25 mb-1å"
            >
            <h1
              class="text-uppercase"
              style="font-family: 'Montserrat';"
            >
              Advantage </h1>
            <h4
              class="text-uppercase"
              style="font-family: 'Montserrat';font-size: 1.67rem"
            >
              Investigations</h4>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-center"
            style="color: #b4b7bd;"
          />
          <hr>
          <b-card-text class="mb-2" />
          <b-card-text
            class="text-center"
            style="color: #b4b7bd;"
          >
            Quick Submission - No login required
          </b-card-text>
          <b-button
            variant="primary"
            block
          >
            <router-link
              to="/cases/add/public"
              style="color:white !important;"
            >
              Submit a Case Now
            </router-link>
          </b-button>
          <hr>
          <b-card-text class="mb-2" />
          <b-card-text
            class="text-center"
            style="color: #b4b7bd;"
          >
            Or log in to your SmartTrak account with your email
          </b-card-text>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group

                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                    style="background-color: ghostwhite; color: black;"
                  />
                  <small class="text-danger">{{ loginError && loginError.error ? loginError.error : '' }}</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <!-- <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Log In
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import authLogin from '../auth/auth0'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loginError: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async signIn() {
      // authLogin()
      // this.$router.push('/home')
      this.loginError = await this.$oidc.login(this.userEmail)
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.signIn()
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Form Submitted',
          //     icon: 'EditIcon',
          //     variant: 'success',
          //   },
          // })
        }
      })
    },
  },
}
// console.log('process env', process.env.VUE_APP_ClientRoot)

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
hr {
  border-top: 3px solid;
}
.w-10{
  width: 5%;
}

.img-wrapper{
  margin-bottom: 0 !important;
}
</style>
